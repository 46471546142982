<!--
* 创建人：邓国伦
* 日 期：
* 描 述：房间预分配
-->
<!--view 模板-->
<template>
  <div class="container">
    <!--=====================================顶部标题======================================-->
    <div class="page-title">房间预分配</div>
    <!--=====================================内容======================================-->
    <div class="pd10">
      <table class="tb" cellspan="0">
        <tr>
          <td class="td pl10" width="40%">
            <table>
              <tr>
                <td>公寓楼</td>
                <td>
                  <el-cascader
                    v-model="GYLBH"
                    size="small"
                    :options="gyltree"
                    :props="{ value: 'id', label: 'text', children: 'childNodes' }"
                    @change="handleChange"
                    clearable
                  ></el-cascader>
                </td>
                <td rowspan="3" class="tdtip" valign="top">
                  <div>
                    <el-button
                      type="primary"
                      icon="el-icon-search"
                      size="small"
                      :disabled="GYLBH === '' || lc === ''"
                      @click="getfplist"
                      >查询</el-button
                    >
                    <el-button
                      type="primary"
                      icon="el-icon-edit"
                      size="small"
                      :disabled="selfplist.length < 1"
                      @click="fpsave"
                      v-loading="view.fpbtnloading"
                      >分配</el-button
                    >
                  </div>
                  <p>
                    <span>当前房间数：{{ dqfjs }} 床位数：{{ dqcws }}</span>
                    <span>已选房间数：{{ yxfjs }} 床位数：{{ yxcws }}</span>
                  </p>
                </td>
              </tr>
              <tr>
                <td>楼层号</td>
                <td>
                  <el-select v-model="lc" placeholder="请选择" size="small" clearable>
                    <el-option
                      v-for="item in lclist"
                      :key="item.value"
                      :label="item.text"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </td>
              </tr>
              <tr>
                <td>空房间</td>
                <td>
                  <el-select v-model="kfj" placeholder="请选择" size="small" clearable>
                    <el-option
                      v-for="item in kfjopts"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </td>
              </tr>
            </table>
          </td>
          <td class="td pl10" valign="top">
            <table>
              <tr>
                <td>院系</td>
                <td class="">
                  <el-select
                    v-model="BMBH"
                    placeholder="请选择"
                    clearable
                    size="small"
                    @change="bmchange"
                  >
                    <el-option
                      v-for="item in bmlist"
                      :key="item.bmbh"
                      :label="item.bmmc"
                      :value="item.bmbh"
                    >
                    </el-option>
                  </el-select>
                </td>
                <td rowspan="3" class="tdtip pt10" valign="top">
                  <div>
                    <el-button
                      type="primary"
                      icon="el-icon-search"
                      size="small"
                      :disabled="BMBH === '' || ZYBH === ''"
                      @click="getsflist"
                      >查询</el-button
                    >
                    <el-button
                      type="primary"
                      icon="el-icon-edit"
                      size="small"
                      :disabled="selsflist.length < 1"
                      @click="sfsave"
                      v-loading="view.sfbtnloading"
                      >释放</el-button
                    >
                    <el-button
                      type="primary"
                      icon="el-icon-edit"
                      size="small"
                      :disabled="selsflist.length < 1"
                      v-loading="view.yybtnloading"
                      @click="yysave(1)"
                      >允许预约</el-button
                    >
                    <el-button
                      type="primary"
                      icon="el-icon-edit"
                      size="small"
                      :disabled="selsflist.length < 1"
                      @click="yysave(0)"
                      v-loading="view.yybtnloading"
                      >取消预约</el-button
                    >
                  </div>
                  <p>
                    <span
                      >总共：{{ fman + fwoman }} 男：{{ fman }} 女：{{ fwoman }} 未定
                      {{ fno }}</span
                    >
                    <span>当前房间数：{{ fdqfjs }} 床位数：{{ fdqcws }}</span>
                    <span>已选房间数：{{ fyxfjs }} 床位数：{{ fyxcws }}</span>
                  </p>
                </td>
              </tr>
              <tr>
                <td>专业</td>
                <td>
                  <el-select v-model="ZYBH" placeholder="请选择" size="small" clearable>
                    <el-option
                      v-for="item in zylist"
                      :key="item.zybh"
                      :label="item.zymc"
                      :value="item.zybh"
                    >
                    </el-option>
                  </el-select>
                </td>
              </tr>
              <tr>
                <td>性别</td>
                <td>
                  <el-select v-model="XB" placeholder="请选择" size="small" clearable>
                    <el-option
                      v-for="item in xbopts"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td class="td">
            <el-table
              ref="fptable"
              v-loading="view.fploading"
              :data="fplist"
              :height="view.windowHeight - 270"
              border
              style="width: 100%"
              header-cell-class-name="tableStyle"
              highlight-current-row
              :row-style="{ height: '20px' }"
              :cell-style="{ padding: '5px' }"
              @selection-change="fphandleSelectionChange"
            >
              <el-table-column type="selection" width="55"> </el-table-column>
              <el-table-column prop="fjbh" label="房间号" width="100"> </el-table-column>
              <el-table-column prop="xbxz" label="性别" width="60"> </el-table-column>
              <el-table-column prop="cws" label="床位数" width="100"> </el-table-column>
              <el-table-column prop="rzzrs" label="已住人数"> </el-table-column>
            </el-table>
          </td>
          <td class="td">
            <el-table
              ref="fptable"
              v-loading="view.sfloading"
              :data="sflist"
              :height="view.windowHeight - 270"
              border
              style="width: 100%"
              header-cell-class-name="tableStyle"
              highlight-current-row
              :row-style="{ height: '20px' }"
              :cell-style="{ padding: '5px' }"
              @selection-change="sfhandleSelectionChange"
            >
              <el-table-column type="selection" width="55"> </el-table-column>
              <el-table-column prop="fjbh" label="房间号" width="100"> </el-table-column>
              <el-table-column prop="xbxz" label="性别" width="60"> </el-table-column>
              <el-table-column prop="cws" label="床位数" width="100"> </el-table-column>
              <el-table-column prop="rzzrs" label="已住人数" width="100">
              </el-table-column>
              <el-table-column prop="isforapp" label="可预约" width="80">
                <template slot-scope="scope">
                  <el-tag v-if="scope.row.isForApp === 1" type="success">是</el-tag>
                  <el-tag v-else type="warning">否</el-tag>
                </template>
              </el-table-column>
              <el-table-column prop="bmmc" label="部门/专业">
                <template slot-scope="scope">
                  {{ scope.row.bmmc }}/{{ scope.row.zymc }}
                </template>
              </el-table-column>
            </el-table>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>
<!-- javascript脚本-->
<script>
import sg from "../../api/gygl";
import { getStuBM, getZYList } from "../../api/jcxx";
export default {
  data() {
    return {
      view: {
        windowHeight: document.documentElement.clientHeight,
        fploading: false,
        sfloading: false,
        fpbtnloading: false,
        sfbtnloading: false,
        yybtnloading: false,
      },
      GYLBH: "",
      gyltree: [],
      kfj: 2,
      lc: "",
      lclist: [],
      kfjopts: [
        { label: "空房间", value: 1 },
        { label: "包含已入住的房间", value: 2 },
      ],
      // 院系 专业 性别
      BMBH: "",
      bmlist: [],
      ZYBH: "",
      zylist: [],
      XB: "",
      xbopts: [
        { label: "男", value: "男" },
        { label: "女", value: "女" },
      ],
      fplist: null,
      sflist: null,
      dqfjs: 0,
      dqcws: 0,
      yxfjs: 0,
      yxcws: 0,
      fdqfjs: 0,
      fdqcws: 0,
      fyxfjs: 0,
      fyxcws: 0,
      fman: 0,
      fwoman: 0,
      fno: 0,
      selfplist: [],
      selsflist: [],
    };
  },
  created() {
    this.treeloading = true;
    sg.getgyltree().then((res) => {
      this.treeloading = false;
      if (res.code === 200) {
        this.gyltree = res.data;
      }
    });
    getStuBM().then((res) => {
      this.bmlist = res.data;
    });
  },
  watch: {
    fplist(val) {
      this.dqfjs = val.length;
      this.dqcws = 0;
      val.find((m) => {
        this.dqcws += m.cws;
      });
    },
    selfplist(val, oval) {
      this.yxfjs = val.length;
      this.yxcws = 0;
      val.find((m) => {
        this.yxcws += m.cws;
      });
    },
    sflist(val) {
        this.fdqfjs = val.length;
        this.fyxcws = 0;
          this.fman = 0;
           this.fwoman = 0;
            this.fno = 0;
        val.find(m => {
            this.fdqcws += m.cws;
            if (m.xbxz === '男') {
            this.fman += m.cws;
        } else if (m.xbxz === '女') {
            this.fwoman += m.cws;
        } else {
            this.fno += m.cws;
        }
        });
    },
    selsflist(val) {
        this.fyxfjs = val.length;
        this.fyxcws = 0;
        val.find(m => {
            this.fyxcws += m.cws;
        })
    }
  },
  methods: {
    handleChange(value) {
      var dat = {
        gylbh: value[value.length - 1],
      };
      this.lclist = [];
      sg.getgylformdata(dat).then((res) => {
        if (res.code === 200 && res.data.lcs > 0) {
          for (var i = 1; i <= res.data.lcs; i++) {
            this.lclist.push({ id: i, text: i + "楼" });
          }
        }
      });
    },
    fphandleSelectionChange(val) {
      this.selfplist = val;
    },
    sfhandleSelectionChange(val) {
      this.selsflist = val;
    },
    bmchange(val) {
      this.zylist = [];
      var dat = {
        BMBH: this.BMBH,
      };
      getZYList({ queryJson: JSON.stringify(dat) }).then((res) => {
        this.zylist = res.data;
      });
    },
    getfplist() {
      this.view.fploading = true;
      var dat = {
        GYLBH: this.GYLBH[this.GYLBH.length - 1],
        LCH: this.lc,
        KFJ: this.kfj,
        ZYBH: "null",
      };
      sg.getfjlist({ queryJson: JSON.stringify(dat) }).then((res) => {
        this.view.fploading = false;
        this.fplist = res.data;
      });
    },
    getsflist() {
      this.view.sfloading = true;
      var dat = {
        ZYBH: this.ZYBH,
        XBXZ: this.XB,
      };
      sg.getfjlist({ queryJson: JSON.stringify(dat) }).then((res) => {
        this.view.sfloading = false;
        this.sflist = res.data;
      });
    },
    yuyuechange(row) {},
    fpsave() {
      if (this.BMBH === "" || this.ZYBH === "" || this.XB === "") {
        this.$message.error("请选择院系、专业、性别");
        return false;
      }
      var fjbhs = this.selfplist.map((v) => v.fjbh);
      var dat = {
        FJBH: fjbhs,
        YFPZYBH: this.ZYBH,
        XBXZ: this.XB,
      };
      this.view.fpbtnloading = true;
      sg.yfpfj(dat).then((res) => {
        this.view.fpbtnloading = false;
        if (res.code === 200) {
          this.$message.success(res.message);
          this.getfplist();
          this.getsflist();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    sfsave() {
      var fjbhs = this.selsflist.map((v) => v.fjbh);
      var dat = {
        FJBH: fjbhs,
      };
      this.view.sfbtnloading = true;
      sg.sffj(dat).then((res) => {
        this.view.sfbtnloading = false;
        if (res.code === 200) {
          this.$message.success(res.message);
          this.getsflist();
          this.getfplist();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    yysave(val) {
      var fjbhs = this.selsflist.map((v) => v.fjbh);
      var dat = {
        FJBH: fjbhs,
        ISForApp: val,
      };
      this.view.yybtnloading = true;
      sg.yyfj(dat).then((res) => {
        this.view.yybtnloading = false;
        if (res.code === 200) {
          this.$message.success(res.message);
          this.getsflist();
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },
  computed: {},
  mounted() {},
};
</script>
<!-- CSS样式 -->
<style>
.container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  position: relative;
  overflow: hidden;
}
.page-title {
  height: 32px;
  line-height: 32px;
  border-bottom: 1px solid #ccc;
  color: #666;
  font-weight: bold;
  padding-left: 9px;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
}
.con-top {
}
.con-body-left {
  display: block;
  float: left;
  width: 40%;
  border: 1px solid #ccc;
}
.con-body-right {
  float: right;
  width: 59%;
  border: 1px solid #ccc;
}
.tb {
  width: 100%;
  border-collapse: collapse;
}
.tb .td {
  border: 1px solid #e4e7ed;
}
.tdtip {
  padding-left: 10px;
}
.tdtip p span {
  display: block;
  height: 26px;
  color: #f56c6c;
}
</style>
